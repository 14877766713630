<template>
    <div class="ui-layout-container ui-layout-flex ui-layout-flex--column ui-height-100">
        <iframe v-if="importUrl" :src="importUrl" class="ui-height-100" frameborder="0"></iframe>
    </div>
</template>
<script>
export default {
    name: 'Running',
    data() {
        return {
            importUrl: 'https://bs-elec.ynlxkj.cn/big-screen/running'
        };
    },
    methods: {},
    // watch: {
    //     $route: {
    //         handler(val) {
    //             if (this.$route.query && this.$route.query.url) {
    //                 this.importUrl = '';
    //                 this.$nextTick(() => {
    //                     this.importUrl = this.$route.query.url;
    //                 });
    //             }
    //         },
    //         immediate: true
    //     }
    // },
    created() {}
};
</script>
